export const styles = [
  "Contemporary",
  "Modern",
  "Traditional",
  "Rustic",
  "Minimalist",
  "Industrial",
  "Coastal",
  "Bohemian",
  "Scandinavian",
  "Mid-Century Modern",
  "Art Deco",
  "French Country",
  "Shabby Chic",
  "Hollywood Regency",
  "Tropical",
  "Farmhouse",
  "Craftsman",
  "Gothic",
  "Asian Zen",
  "Southwestern",
  "Victorian",
  "Transitional",
  "Mediterranean",
  "Moroccan",
  "Eclectic",
  "Nautical"
];

export const styleDescriptions = {
  Contemporary:
    "Embrace clean lines and subtle sophistication in your {room}. This modern style features deliberate use of texture, neutral color palettes, and sleek furniture. Contemporary {rooms} often showcase large windows, minimalist decor, and a focus on open areas and light, creating an atmosphere of elegant simplicity.",

  Modern:
    "Design a {room} that celebrates simplicity with clean, crisp lines and a refined color palette. Modern {rooms} incorporate materials like metal, glass, and steel, resulting in a sleek and uncluttered atmosphere. Furniture is often raised off the floor, enhancing the sense of space and lightness.",

  Traditional:
    "Transform your {room} with classic design inspired by 18th and 19th-century European decor. Traditional {rooms} boast rich color palettes, ornate details, and elegant furnishings. Expect to see crown molding, antique pieces, and symmetrical arrangements that create a timeless and sophisticated atmosphere.",

  Rustic:
    "Bring the beauty of nature into your {room} with a rustic design. This style emphasizes rugged, natural beauty focusing on organic textures and earthy colors. Rustic {rooms} often feature exposed wooden beams, stone elements, and handcrafted furniture, creating a warm and inviting atmosphere that celebrates the outdoors.",

  Minimalist:
    "Create a {room} that focuses on simplicity and functionality. Minimalist design employs a monochromatic color palette and clean lines to instill a sense of calm and order. These {rooms} often feature multi-functional furniture, hidden storage, and a clutter-free environment that promotes relaxation and clarity.",

  Industrial:
    "Design a {room} that combines raw, unfinished elements with sleek, modern features. Industrial {rooms} often showcase exposed brick walls, metal accents, and repurposed objects. This style creates a unique blend of vintage and contemporary, resulting in a bold and edgy atmosphere.",

  Coastal:
    "Transform your {room} into a beach-inspired retreat. Coastal design features light, airy {rooms} with blue, white, and sandy color palettes. These {rooms} often include natural textures like jute and weathered wood, along with nautical accents that create a relaxed and refreshing atmosphere.",

  Bohemian:
    "Create an eclectic and global-inspired {room} that incorporates vibrant colors, patterns, and textures. Bohemian {rooms} are characterized by a mix of vintage and modern elements, layered textiles, and unique artwork. This style encourages self-expression and creates a free-spirited, artistic atmosphere.",

  Scandinavian:
    "Design a {room} that combines beauty, simplicity, and functionality. Scandinavian {rooms} are characterized by pale colors, natural materials, and minimalist shapes. This style emphasizes clean lines, practical furniture, and plenty of natural light to create a cozy yet spacious feel.",

  "Mid-Century Modern":
    "Transform your {room} with a retro style from the mid-1900s. Mid-Century Modern design features organic shapes, simple fabrications, and a seamless flow between indoor and outdoor living. These {rooms} often include iconic furniture pieces and a mix of traditional and non-traditional materials.",

  "Art Deco":
    "Create a glamorous and luxurious {room} characterized by bold geometric patterns, lavish ornamentation, and metallic finishes. Art Deco {rooms} feature symmetrical designs, exotic materials, and a sense of opulence. This style combines modernist styles with fine craftsmanship and rich materials.",

  "French Country":
    "Design a warm and inviting {room} that combines rustic elements with elegant details. French Country {rooms} feature soft, muted colors and intricate patterns. This style incorporates distressed woodwork, ornate ironwork, and comfortable furniture to create a charming and sophisticated atmosphere.",

  "Shabby Chic":
    "Transform your {room} with a vintage-inspired style that combines worn, pastoral elegance with modern touches. Shabby Chic {rooms} feature a soft, feminine feel with distressed furniture, pastel colors, and vintage accessories. This style creates a comfortable and romantic atmosphere.",

  "Hollywood Regency":
    "Create a glamorous and bold {room} that combines vibrant colors with metallic and glass accents. Hollywood Regency {rooms} feature luxurious fabrics, bold patterns, and statement pieces. This style emphasizes drama and sophistication, creating a space that feels both opulent and inviting.",

  Tropical:
    "Design a {room} inspired by lush vegetation and warm climates. Tropical {rooms} feature bright colors, natural textures, and leafy patterns. This style incorporates elements like bamboo, rattan, and tropical plants to create a relaxed and exotic atmosphere.",

  Farmhouse:
    "Transform your {room} by combining rustic charm with modern convenience. Farmhouse {rooms} feature practical furnishings, warm colors, and vintage accessories. This style creates a cozy and welcoming atmosphere with a focus on simplicity and functionality.",

  Craftsman:
    "Create a {room} that emphasizes handcrafted wood, rich colors, and a mix of natural materials. Craftsman {rooms} feature built-in furniture, exposed beams, and a focus on quality craftsmanship. This style creates a warm and inviting atmosphere with a strong connection to nature.",

  Gothic:
    "Design a dark and dramatic {room} featuring pointed arches, rich color palettes, and ornate decorations. Gothic {rooms} often incorporate elements like stained glass, intricate woodwork, and medieval-inspired furniture. This style creates a mysterious and romantic atmosphere.",

  "Asian Zen":
    "Transform your {room} into a tranquil retreat that balances simplicity and nature. Asian Zen {rooms} feature natural materials, minimalist decor, and a sense of harmony. This style incorporates elements like low-profile furniture, bamboo accents, and a neutral color palette to create a peaceful atmosphere.",

  Southwestern:
    "Create a {room} inspired by Native American and Spanish colonial design. Southwestern {rooms} feature earthy colors, natural materials, and bold patterns. This style incorporates elements like terracotta tiles, woven textiles, and rustic wood furniture to create a warm and inviting atmosphere.",

  Victorian:
    "Design an opulent and ornate {room} inspired by 19th-century style. Victorian {rooms} are characterized by rich colors, elaborate patterns, and intricate details. This style features ornate furniture, heavy draperies, and decorative accessories that create a sense of luxury and sophistication.",

  Transitional:
    "Create a {room} that blends traditional elegance with contemporary lines and finishes. Transitional {rooms} feature a mix of classic and modern elements, creating a timeless look. This style incorporates neutral color palettes, comfortable furniture, and subtle patterns for a balanced and harmonious atmosphere.",

  Mediterranean:
    "Transform your {room} with inspiration from the coastal regions of southern Europe. Mediterranean {rooms} feature warm colors, ornate details, and rustic elements. This style incorporates terracotta tiles, wrought iron accents, and textured walls to create a sun-drenched, relaxed atmosphere.",

  Moroccan:
    "Design an exotic {room} featuring vibrant colors, intricate patterns, and ornate decorations inspired by North African design. Moroccan {rooms} often include colorful tiles, arched doorways, and plush seating areas. This style creates a rich and immersive atmosphere with a focus on comfort and visual interest.",

  Eclectic:
    "Create a unique and personalized {room} by harmoniously blending various styles, periods, and trends. Eclectic {rooms} feature a curated mix of furniture, artwork, and accessories that reflect the owner's personality. This style allows for creativity and self-expression while maintaining a cohesive overall look.",

  Nautical:
    "Transform your {room} with seaside and boating elements. Nautical {rooms} feature navy blues, crisp whites, and maritime accents. This style incorporates elements like striped patterns, rope details, and seafaring decor to create a fresh and breezy atmosphere reminiscent of coastal living.",
}