import React from "react"
import Link from "next/link"

const cleanUrlSegment = segment => {
  return segment
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^a-z0-9-]/g, "")
}

const StyleLinkList = ({
  styles,
  selectedRoom,
  baseUrl = "/interior-design-ai",
  isStartPage = false,
  title = "Room Styles",
  description = "Explore a wide range of carefully curated interior design styles, each designed to spark your creativity and help you find the perfect look for any space. Whether you're seeking a cozy, rustic retreat, a sleek and modern sanctuary, or a charming, vintage-inspired room, our collection of room style categories has something to inspire every design vision."
}) => {
  return (
    <section className="relative pt-4 mt-12">
      <div className="container mx-auto px-4">
        {/* Title Section */}
        <div className="text-center mb-16">
          <h2 className="text-3xl font-heading text-gray-800 text-left">{title}</h2>
          <p className="text-gray-600 mt-4 text-left md:w-1/2">{description}</p>
        </div>
        
        {/* Categories Section */}
        <div className="flex flex-wrap justify-between">
          {styles.map(style => {
            const url =
              isStartPage || !selectedRoom
                ? `${baseUrl}/${cleanUrlSegment(`${style}`)}`
                : `${baseUrl}/${cleanUrlSegment(`${style}-${selectedRoom}`)}`

            return (
              <div key={style} className="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 px-4 mb-6">
                <a 
                  href={url} 
                  title={`${style} ${selectedRoom || "Room"}`}
                  className="font-heading text-gray-900 capitalize text-left hover:text-gray-600 transition-colors block"
                >
                  {style}
                </a>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default StyleLinkList
