import React, { useState, useRef, useEffect } from 'react'
import Image from 'next/future/image'

const TestimonialSlider = () => {
  const [activeSlide, setActiveSlide] = useState(0)
  const [slideWidth, setSlideWidth] = useState(0)
  const [showBefore, setShowBefore] = useState(Array(5).fill(false))
  const slideRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]
  const slideCount = 5

  useEffect(() => {
    if (slideRefs[0].current) {
      setSlideWidth(slideRefs[0].current.offsetWidth)
    }
  }, [])

  const handlePrevSlide = () => {
    setActiveSlide(prev => (prev > 0 ? prev - 1 : slideCount - 1))
  }

  const handleNextSlide = () => {
    setActiveSlide(prev => (prev < slideCount - 1 ? prev + 1 : 0))
  }

  const toggleBefore = (index) => {
    setShowBefore(prev => {
      const newState = [...prev]
      newState[index] = !newState[index]
      return newState
    })
  }

  const slides = [
    {
      number: "1",
      title: '"Am I doing okay with this space?"',
      afterImage: "https://preview.redd.it/cant-wait-to-unwind-here-after-a-long-day-of-work-i-just-v0-fc62kv9e3qvd1.png?width=640&crop=smart&auto=webp&s=c72eeb1dfdd327c0424fb19c1343387bbea8bf69",
      beforeImage: "https://preview.redd.it/cant-wait-to-unwind-here-after-a-long-day-of-work-i-just-v0-ulta9u1axpvd1.png?auto=webp&s=8f5058dc59305af72d66ffa2d31d409cdcff4f44",
      comments: [
        "https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/RedditComment6.png",
        "https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/Comment1-3.png"
      ]
    },
    {
      number: "2",
      title: '"How can I make it feel more \'designed\'?"',
      afterImage: "https://preview.redd.it/what-does-this-living-room-need-v0-l2owqycskqud1.png?width=640&crop=smart&auto=webp&s=cfa251d0f8802ab07490ad3dc6a93bc506b08042",
      beforeImage: "https://preview.redd.it/what-does-this-living-room-need-v0-adj5vs2hflud1.jpeg?auto=webp&s=576a039792d4141f0416bea7cee101382521dec4",
      comments: [
        "https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/ReeditComment4.png"
      ]
    },
    {
      number: "3",
      title: '"My wife and I are sick of this living room"',
      afterImage: "https://preview.redd.it/my-wife-and-i-are-sick-of-this-living-room-v0-82edst7u5wud1.png?width=640&crop=smart&auto=webp&s=048e0ed19c1951d234640f4eec4a32d136afd07f",
      beforeImage: "https://preview.redd.it/my-wife-and-i-are-sick-of-this-living-room-v0-debgoe8enuud1.jpg?width=1080&crop=smart&auto=webp&s=47f4c29780680e2af94776289d46d5a7f4dba356",
      comments: [
        "https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/RedditComment8.png"
      ]
    },
    {
      number: "4",
      title: '"Kind people of Reddit pls help me choose a rug."',
      afterImage: "https://preview.redd.it/kind-people-of-reddit-pls-help-me-choose-a-rug-or-suggest-v0-e2whs7r8aayd1.png?width=640&crop=smart&auto=webp&s=0bfa1bf5717572243b88314e49419139b66d20c4",
      beforeImage: "/assets/before-after/before-rug.jpeg", 
      comments: [
        "https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/RedditComment9.png",
        "https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/RedditComment10.png"
      ]
    },
    {
      number: "5",
      title: '"Save me from Millennial gray"',
      afterImage: "https://preview.redd.it/save-me-from-millennial-gray-v0-iuipaxj14bvd1.png?width=640&crop=smart&auto=webp&s=c35c69905394de382602a94d500075c90b04720e",
      beforeImage: "https://preview.redd.it/save-me-from-millennial-gray-v0-xhs1qpc3r7vd1.jpg?width=1125&format=pjpg&auto=webp&s=5a9d7d33c7d3bcc362b644314f94278f7623a2d7",
      comments: [
        "https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/RedditComment11.png"
      ]
    }
  ]

  return (
    <section id="testimonials" className="relative pt-28 pb-32 overflow-hidden lg:p-44">
      <div className="relative z-10 container mx-auto px-4">
        <div className="mb-24 ml-5">
          <h2 className="mb-6 font-heading text-5xl sm:text-7xl md:text-9xl xl:text-10xl mx-auto md:mx-0 md:text-left">
            Real stories, real results
          </h2>
          <p className="text-gray-600 font-heading text-base">
            Explore heartfelt reactions and real stories from the Reddit community
          </p>
        </div>

        <div className="relative">
          <div 
            className="flex transition-transform duration-500 ease-in-out"
            style={{ transform: `translateX(-${slideWidth * activeSlide}px)` }}
          >
            {slides.map((slide, index) => (
              <div key={index} ref={slideRefs[index]} className="flex-shrink-0 w-full p-5 md:max-w-md">
                <div className="rounded-lg bg-white shadow-lg p-4 md:p-6 md:pb-8">
                  <div className="flex justify-center items-center font-heading text-2xl md:text-3xl w-12 h-12 text-gray-900 rounded-full mb-4">
                    {slide.number}
                  </div>
                  <p className="text-2xl md:text-3xl mb-6 font-heading">{slide.title}</p>
                  
                  <div className="relative">
                    <div className="flex justify-center">
                      <div className="w-full relative mx-auto">
                        <div className="relative w-full" style={{ paddingBottom: "75%" }}>
                          <div className={`absolute inset-0 transition-opacity duration-300 ${
                            showBefore[index] ? 'opacity-100 z-20' : 'opacity-0 pointer-events-none z-10'
                          }`}>
                            <Image
                              src={slide.beforeImage}
                              alt="Before Image"
                              className="w-full h-full object-cover"
                              width={640}
                              height={480}
                            />
                          </div>
                          
                          <div className={`absolute inset-0 transition-opacity duration-300 ${
                            showBefore[index] ? 'opacity-0 z-10' : 'opacity-100 z-20'
                          }`}>
                            <Image
                              src={slide.afterImage}
                              alt="After Image"
                              className="w-full h-full object-cover"
                              width={640}
                              height={480}
                            />
                          </div>
                        </div>

                        <div className="z-30 absolute left-1/2 transform -translate-x-1/2 mt-4 bottom-2">
                          <button 
                            className="text-gray-600 bg-white px-3 py-1 rounded-md shadow-sm hover:text-black transition duration-300 opacity-80 text-xs whitespace-nowrap"
                            onClick={() => toggleBefore(index)}
                          >
                            {showBefore[index] ? "See the after photo" : "See the before photo"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {slide.comments.map((comment, commentIndex) => (
                    <Image
                      key={commentIndex}
                      className={`rounded-2xl shadow-2xl ${
                        commentIndex === 0 ? 'ml-2 w-10/12' : 'opacity-95 w-10/12 shadow-2xl -mt-4 md:ml-14 ml-10'
                      }`}
                      src={comment}
                      alt={`Comment Image ${commentIndex + 1}`}
                      width={400}
                      height={100}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className="absolute right-4 flex space-x-2">
            <button 
              onClick={handlePrevSlide}
              className="w-10 h-10 flex items-center justify-center bg-gray-200 rounded-full text-gray-700 hover:bg-gray-300"
            >
              ←
            </button>
            <button 
              onClick={handleNextSlide}
              className="w-10 h-10 flex items-center justify-center bg-gray-200 rounded-full text-gray-700 hover:bg-gray-300"
            >
              →
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TestimonialSlider 